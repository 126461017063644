@import 'src/styles/mixins';
@import 'src/styles/variables';

.page-container {
  height: 100%;

  .content {
    height: calc(100% - 56px);
    padding: 16px 20px;
    padding-top: 0;
  }
}

.app-header {
  display: flex;
  justify-content: space-between;
  height: 65px;
  background-color: #4c5ecf;
}

.app-header-left-side {
  display: flex;
  align-items: center;
  padding-left: 24px;
}

.logo {
  display: flex;
  align-items: center;
  margin-right: 24px;
}

.logo-text {
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: #ffffff;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 8px;
}

.navigation {
  display: flex;
  height: 100%;
}

.navigation-link {
  padding: 10px 12px 8px 12px;
  letter-spacing: 0.4px;
  text-align: center;
  text-decoration: none;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.active {
    background-color: rgba(27, 43, 75, 0.2);
    pointer-events: none;
  }
}

.navigation-link-text {
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
}

.app-header-right-side {
  display: flex;
  align-items: center;
  padding-right: 9px;
  height: 100%;
}

.app-header-user-info {
  color: #ffffff;
  padding-right: 10px;
  font-size: 14px;
  letter-spacing: 0.3px;
  text-align: right;
  &-desc {
    font-size: 9px;
  }
}

.app-header-logout {
  padding-left: 14px;
}

.app-header-notification {
  padding-left: 14px;
}

.app-header-contact-support {
  padding-left: 23px;
}
