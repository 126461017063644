.mailer-date-picker {
    .MuiInputLabel-outlined {
        transform: translate(14px, 13px) scale(1);
    }

    .MuiInputLabel-outlined.MuiInputLabel-shrink {
        transform: translate(14px, -6px) scale(0.75);
        background-color: #fff;
        padding-right: 5px;
    }

    .MuiOutlinedInput-input {
        padding: 12px 14px;
    }

    .MuiIconButton-root {
        padding: 6px;
        margin-left: 10px;

        .MuiIconButton-label {
            font-size: 20px;

            .MuiSvgIcon-root {
                font-size: 1em;
            }
        }
    }

    fieldset legend {
        font-size: 0.65em;
    }
}