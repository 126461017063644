@import 'src/styles/variables';

.mailer-textarea {
    .MuiInputLabel-root {
        font-size: 14px;
    }
    .MuiFilledInput-multiline {
        background-color: $lightgrey;
        border-radius: 4px;
        padding: 27px 12px 10px;

        &::after {
            border: 0;
        }

        &::before {
            border: 0;
        }

        &:hover {
            background-color: $grey;

            &::before {
                border: 0;
            }
        }
    }
}