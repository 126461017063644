.rejectModal {
    display: flex;
    flex-direction: column;
    width: 550px;
    min-height: 100px;
    font-weight: 400;
    font-size: 14px;

    .rejectModalItem {
        margin-bottom: 15px;
    }
}
