@import 'src/styles/variables';

.dropdown-zone {
    border-radius: 6px;
    border: 2px dashed $grey;
    background-color: #fff;
    padding: 4px;
    position: relative;

    &:focus {
        outline: $primary-btn-bg-color dashed 2px;
        outline-offset: -2px;
    }

    .file-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        .item {
            display: flex;
            align-items: center;
            flex: 0 1 auto;
            justify-content: space-between;
            background-color: $lightgrey;
            padding: 8px 12px;
            border-radius: 5px;
            margin: 4px 8px 4px 0;
            transition: background-color 250ms;
            max-width: calc(50% - 8px);

            &:hover {
                background-color: $grey;
            }

            .link {
                text-decoration: none;
                font-size: 14px;
                display: flex;
                align-items: center;
                color: $weakTextColor;
                overflow: hidden;

                &:hover, &:active, &:visited {
                    color: $weakTextColor;
                }

                .file-icon {
                    margin-right: 10px;
                    flex-shrink: 0;
                }

                .link-text {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            .delete {
                width: 8px;
                height: 8px;
                margin-left: 10px;
                cursor: pointer;
                flex-shrink: 0;
            }
        }
    }

    .attach {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px 0;
        color: $darkgrey;
        cursor: pointer;
    }
}
