@import 'src/styles/variables';

.custom-drawer {
    display: flex;
    flex-direction: column;
    .MuiDrawer-paper {
        max-width: 50%;
    }
    .drawer-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 24px;
        border-bottom: 1px solid $lightgrey;

        .title {
            font-size: 18px;
        }
    }
}


