.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    &__col-left {
        display: flex;
        align-items: center;
    }
    &__back-btn {
        background-color: #f9f9fb!important;
        width: 45px;
        min-width: 45px!important;
        height: 45px;
        padding: 0!important;
        margin-right: 10px!important;
        .MuiButton-startIcon {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__title {
        font-size: 24px;
    }
}
