@import 'src/styles/variables';
@import 'src/styles/mixins';

.dictionary-content {
    flex: 1;
    overflow: auto;
    position: relative;
}

.dictionary-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
    padding: 16px 12px;
}

.search-mini {
    width: 250px;
}

.search-mini-note {
    width: 150px;
    margin: 0 auto;
    color: #a39e9e;
    padding-top: 5px;
}

.dictionary-footer {
    padding: 16px 24px;
    border-top: 1px solid $lightgrey;
    display: flex;
    justify-content: space-between;
    box-shadow: 0px -20px 40px rgba(72, 89, 122, 0.05);

    .chosen-counter {
        @include flex-centered;

        .count {
            font-size: 16px;
            margin-left: 4px;
            color: $tab-underline-color;
        }
    }
}