$table-head-bg-color: rgb(249, 249, 251);
$table-body-bg-color: #fff;
$text-color: rgb(27, 43, 75);

.root-table.MuiPaper-root {
    position: relative;
    box-shadow: none;
    width: 100%;
    height: 100%;
    overflow: auto;

    .table-container {
        height: calc(100% - 52px);
    }

    .extended-table {
        table-layout: fixed;
        &__head {
            background: $table-head-bg-color;
        }
        &__row {
            &:hover {
                background-color: transparent;
            }
        }
        &__cell {
            font-size: 12px;
            line-height: 15px;
            color: $text-color;
            padding: 12px 12px;
        }
        &__cell-head {
            width: 170px;
            font-weight: bold;
        }
        &__cell-body {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
        }
        .cell-sticky {
            position: sticky;
            left: 66px;
            z-index: 3;
            &--body {
                z-index: 2;
                background: $table-body-bg-color;
            }
        }
        &__cell-checkbox {
            width: 66px;
            z-index: 3;
        }
        &__cell-checkbox--body {
            position: sticky;
            z-index: 2;
            left: 0;
            background: $table-body-bg-color;
        }
        &__label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__sort-label {
            width: 100%;
            svg {
                fill: $text-color;
                stroke: $text-color;
            }
        }
        &__pagination {
            color: $text-color;
            font-size: 12px;
            line-height: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p {
                font-size: 12px;
                line-height: 15px;
            }
            svg {
                color: $text-color;
                stroke: $text-color;
                font-size: 16px;
                top: 0;
                bottom: 0;
                margin: auto;
            }
        }

        .settings-button {
            padding: 4px; 
        }
        .select-menu {
            padding: 0;
            min-width: 56px;
            justify-content: flex-start;
            .MuiButton-endIcon {
                margin-left: 0;
            }
        }
    }
}

.table-menu {
    .MuiPaper-root {
        max-width: 200px;
    }
    .menu-item {
        justify-content: start;
        height: 40px;
        width: 100%;
        padding: 6px 16px;
    }
}