@import "src/styles/variables";

.detail-mail {
    margin-bottom: 20px;
    &__title {
        margin-bottom: 20px;
        color: #4c5ecf;
        font-size: 16px;
        line-height: 19px;
    }
}

.detail-mail-list {
    display: flex;
    align-items: center;
    &__item {
        margin-right: 30px;
    }
}

.detail-mail-item {
    &__title {
        font-size: 12px;
        line-height: 16px;
        font-weight: 600;
        color: #616284;
    }
    &__desc {
        color: #1b2b4b;
        font-size: 14px;
        line-height: 18px;
    }
}

.MuiButtonBase-root.message-button {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: #616284;
    padding: 15px 25px;
    background: #f9f9fb;
    border-radius: 5px;
    text-transform: none;
}