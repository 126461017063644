@import 'src/styles/variables';

.modal {
    .MuiDialogTitle-root {
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $lightgrey;
    }

    .MuiDialogActions-root {
        border-top: 1px solid $lightgrey;
        padding: 16px 24px;
    }

    .footer-left-side {
        display: block;
    }
}