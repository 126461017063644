.catalog {
    position: relative;
}

.catalog-menu {
    .MuiList-root {
        max-width: 350px;
        max-height: 350px;
    }
    .MuiListItem-root {
        padding-top: 4px;
        padding-bottom: 4px;
    }
    .MuiListItemIcon-root {
        min-width: 36px;
    }
    .MuiTypography-root {
        font-size: 14px;
    }
}