@import 'src/styles/variables';

.combo-list {
    .label {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .error {
        color: $errorTextColor;
        margin-left: 14px;
        margin-right: 14px;
        font-size: 12px;
    }
    
    .container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: center;
    }

    .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: $weakTextColor;
        background: $lightgrey;
        padding: 8px 12px;
        border-radius: 5px;
        margin: 4px 8px 4px 0;
        cursor: default;
        transition: background-color 250ms;
        max-width: calc(33% - 6px);

        &:hover {
            background-color: $grey;
        }

        .item-title {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .delete-icon {
            width: 8px;
            height: 8px;
            margin-left: 10px;
            cursor: pointer;
            flex-shrink: 0;
        }
    }

    .MuiIconButton-root {
        padding: 6px;
    
        .add-icon {
            color: $primary-btn-bg-color;
            font-size: 20px;
        }
    }    

    .more-button {
        cursor: pointer;
    }
}

.MuiButton-root.combo-list-hide-button {
    padding: 4px 12px;
    border-radius: 5px;
    margin: 4px 8px 4px 0;
    text-transform: none;
}