@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import 'src/styles/variables';

@font-face {
    font-family: 'Roboto';
    src: url("../assets/fonts/Roboto-Thin.woff2") format('woff2');
    font-weight: 100;
}

@font-face {
    font-family: 'Roboto';
    src: url("../assets/fonts/Roboto-Light.woff2") format('woff2');
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url("../assets/fonts/Roboto-Regular.woff2") format('woff2');
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url("../assets/fonts/Roboto-Medium.woff2") format('woff2');
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url("../assets/fonts/Roboto-Bold.woff2") format('woff2');
    font-weight: 700;
}

* {
    box-sizing: border-box;
}

html,
body,
#root {
    height: 100%;
}

body {
    margin: 0;
    font-family:
        'Roboto',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        sans-serif;
    font-weight: normal;
    font-size: 14px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-track {
    background: $lightgrey;
}
::-webkit-scrollbar-thumb {
    background: $darkgrey;
}
::-webkit-scrollbar-thumb:hover {
    background: $darkgrey;
}

// OVERRIDING STYLES GLOBALLY
#root .MuiTabs-indicator {
    background-color: $tab-underline-color;
}

.MuiTypography-root.MuiTypography-body1 {
    font-family:
        'Proxima Nova',
        -apple-system,
        BlinkMacSystemFont,
        'Segoe UI',
        sans-serif;
}