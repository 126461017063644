@import 'src/styles/variables';

.file-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;

    .item {
        display: flex;
        align-items: center;
        flex: 0 1 auto;
        justify-content: space-between;
        background-color: $lightgrey;
        padding: 8px 12px;
        border-radius: 5px;
        margin: 4px 8px 4px 0;
        transition: background-color 250ms;
        max-width: calc(50% - 8px);
        &-success {
            background-color: #f2fcf2;
            & .link {
                color: #24ab2e !important;
                font-weight: bold;
            }
        }
        &-error {
            background-color: #ffedef;
            & .link {
                color: #c15051 !important;
                font-weight: bold;
            }
        }
        &-warning {
            background-color: #fffbed;
            & .link {
                color: #dbb93d !important;
                font-weight: bold;
            }
        }

        &:hover {
            background-color: $grey;
        }

        .link {
            text-decoration: none;
            font-size: 14px;
            display: flex;
            align-items: center;
            color: $weakTextColor;
            overflow: hidden;

            &:hover, &:active, &:visited {
                color: $weakTextColor;
            }

            .file-icon {
                margin-right: 10px;
                flex-shrink: 0;
            }

            .link-text {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .delete {
            width: 8px;
            height: 8px;
            margin-left: 10px;
            cursor: pointer;
            flex-shrink: 0;
        }
    }
}
