.tender-send-modal {
    .form {
        .subject {
            font-weight: 400;
            font-size: 22px;
            margin: 0;
        }
        .MuiGrid-spacing-xs-3 {
            margin: 0 -12px;
        }
        .MuiGrid-root {
            position: relative;
        }
    }
    .switch {
        margin-right: auto;
        margin-left: 0;
    }
    .accordion-error {
        .MuiAccordionSummary-root {
            background: #facdcd;
            .title {
                color: red;
            }
        }
    }
}


