@import 'src/styles/variables';

.tenders {
    height: 100%;
    .tenders-table-container {
        height: calc(100% - 133px);
        width: 100%;
    }
}

.tender-headline {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid rgb(226, 228, 233);
    min-height: 38px;
}

.nav-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
}

.nav-list-item {
    margin-right: 17px;
}

.nav-list-link {
    text-decoration: none;
    .MuiButton-label {
        position: relative;
        display: inline-block;
        width: auto;
        padding-bottom: 16px;
        &:before {
            content: "";
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: rgb(76, 94, 207);
            opacity: 0;
        }
    }
    &.active {
        pointer-events: none;
        .MuiButton-label {
            &:before {
                opacity: 1;
            }
        }
    }
}

.nav-list-button.MuiButtonBase-root {
    position: relative;
    text-transform: none;
    padding: 8px;
    padding-top: 16px;
    padding-bottom: 0;
    font-weight: 300;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: rgb(76, 94, 207);
    &:hover {
        background-color: transparent;
    }
}

.tender-buttons {
    display: flex;
    flex-direction: row;
    padding-top: 22px;
    padding-bottom: 20px;
}

.tender-item-button {
    margin-right: 15px;
}

.tableLink {
    display: block;
    max-width: 400px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: rgb(27,43,75);
}

.icon-column {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    svg {
        margin-right: 5px;
    }
}