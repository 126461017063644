@import 'src/styles/variables';

.customButton {

    &.MuiButton-root {
        color: #fff;
        text-transform: none;
    }

    &.primary {
        background: $primary-btn-bg-color;
        opacity: 0.85;

        &:hover {
            opacity: 1;
            background: $primary-btn-bg-color;
        }

        &:disabled {
            background: $grey;
        }
    }

    &.secondary {
        background: $warning-btn-bg-color;
        color: $white;

        &:hover {
            opacity: 1;
            background: $warning-btn-bg-color;
        }

        &:disabled {
            background: $grey;
        }
    }

    &.warning {
        opacity: 0.85;
        background: $warning-btn-bg-color;

        &:hover {
            opacity: 1;
            background: $warning-btn-bg-color;
        }
    }

    &.common {
        background: $lightgrey;
        opacity: 0.85;
        color: $mainTextColor;

        &:hover {
            opacity: 1;
            background: $grey;
        }

        &:disabled {
            background: $grey;
        }
    }
}